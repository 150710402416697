<template>
  <div v-if="section" class="row">
    <div class="col-md-12 d-flex justify-content-between mb-2">
      <label class="mr-2">Redirect URL On Submission</label>
      <b-form-checkbox
          v-model="section.IsRedirect"
          switch
        ></b-form-checkbox>
    </div>
    <div v-if="section.IsRedirect" class="col-md-12">
      <div class="form-group">
        <label>Redirect URL</label>
        <input
          v-model="section.RedirectLink"
          type="text"
          class="form-control"
          placeholder="example.com"
        />
      </div>
    </div>
    <div v-else class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Content</label>
            <textarea
              v-model="section.Content"
              class="form-control"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <ColorInput v-model="section.BackgroundColor" label="Background color"/>
        </div>
        <div class="col-md-6">
          <ColorInput v-model="section.Color" label="Text color"/>
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.FontSize" label="Font size" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.Padding" label="Padding" />
        </div>
        <div class="col-md-12 d-flex justify-content-between mt-3">
          <label class="mr-2">Share social</label>
          <b-form-checkbox
            v-model="section.isShareSocial"
            switch
          ></b-form-checkbox>
        </div>
        <div v-if="!section.isShareSocial" class="col-md-12 mt-2">
          <div class="form-group">
            <label>Back label</label>
            <input
              v-model="section.BackLabel"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ColorInput from './ColorInput'
// import NumberInput from './NumberInput'

export default {
  components: {
    NumberInput: () => import('./NumberInput'),
    ColorInput: () => import('./ColorInput'),
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>
